import logo from './rtm-logo.gif';
import './App.css';
import { useState } from 'react';
import StarRating from './StarRating';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function App() {
  const [rating, setRating] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [employeeNameOrId, setEmployeeNameOrId] = useState("");
  const [billNumberOrDate, setBillNumberOrDate] = useState("");
  const [feedback, setFeedback] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
      if (value.length === 1) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: undefined,
        }));
      }
    }
  };

  const apiUrl = process.env.REACT_APP_API_URL;
 // console.log("Environment:", process.env.NODE_ENV);
 // console.log("API URL:", apiUrl);

  const validateForm = () => {
    const newErrors = {};
    if (rating === null) {
      newErrors.rating = "Please select a star rating.";
    }
    if (customerName.length < 2) {
      newErrors.customerName = "Enter your Full Name";
    }
    if (phoneNumber.length !== 10) {
      newErrors.phoneNumber = "Phone number must be exactly 10 digits.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if  (!validateForm() || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const formData = {
      rating,
      customerName,
      customerPhoneNumber: phoneNumber,
      employeeNameOrId,
      billNumberOrDate,
      feedback: rating >= 4 ? '' : feedback 
    };

    try {
      const response = await fetch(`${apiUrl}/review`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        if (rating >= 4) {
          window.location.href = "https://g.page/r/CSIg2OyH7_YtEAI/review";
        } else {
          setIsSubmitted(true);
        }
      } else {
        alert("Failed to submit feedback.");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      alert("An error occurred while submitting your feedback.");
    } finally {
      setIsSubmitting(false); 
    }
  };

  const shouldShowFeedback = rating < 4;

  return (
    <div className="whole-component">
      <div className="outer-component">
        <div className='form-outer-cont'>
          <img src={logo} className="App-logo" alt="logo" />
          <div className='thanks-quote'>Thank you for shopping with us! We would like to hear your valuable feedback. Tell us about your experience with <span>Ram Thanganagai Maligai.</span></div>
          <StarRating
            rating={rating}
            setRating={(value) => {
              setRating(value);
              if (value !== null) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  rating: undefined,
                }));
              }
            }}
            error={errors.rating}
            isSubmitted={isSubmitted}
          />

          {isSubmitted ? (
            <div className="success-message">
              <CheckCircleOutlineIcon className="success-icon" fontSize="large" color="success" />
              <p>Form successfully submitted. Thank you for your feedback!</p>
            </div>
          ) : (
            <>
              <div className='form-group'>
                <label>Full name</label>
                <input
                  type="text"
                  value={customerName}
                  onChange={(e) => {
                    setCustomerName(e.target.value);
                    if (e.target.value.length >= 3) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        customerName: undefined,
                      }));
                    }
                  }}
                  placeholder="Enter your full name"
                  className={errors.customerName ? 'input-error' : ''}
                />
                {errors.customerName && <div className="error-message">{errors.customerName}</div>}
              </div>

              <div className='form-group'>
                <label>Phone Number (+91)</label>
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  placeholder="Enter 10-digit phone number"
                  className={errors.phoneNumber ? 'input-error' : ''}
                />
                {errors.phoneNumber && <div className="error-message">{errors.phoneNumber}</div>}
              </div>

              <div className='form-group'>
                <label>Employee ID</label>
                <input
                  type="text"
                  value={employeeNameOrId}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                    setEmployeeNameOrId(numericValue);
                  }}
                  placeholder="Enter employee ID"
                />
              </div>

              <div className='form-group'>
                <label>Bill No / Date</label>
                <input
                  type="text"
                  value={billNumberOrDate}
                  onChange={(e) => setBillNumberOrDate(e.target.value)}
                  placeholder="Enter bill number or date"
                />
              </div>

              {shouldShowFeedback && (
                <div className='form-group'>
                  <label>Take a moment to share about your experience with us</label>
                  <textarea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Share your feedback"
                  />
                </div>
              )}

              <button onClick={handleSubmit} disabled={isSubmitted || isSubmitting}>
                {rating >= 4 ? 'Proceed' : 'Submit'}
              </button>
            </>
          )}
        </div>

        <div className='copy-cont'>
          Copyright © 2024 Ram Thanganagai Maligai. All Rights Reserved.
        </div>
        <span className="tech-container">
          <div className='powered-by'>Powered by</div>
          <div className='thofa'>
            <span className="thofa-text">THOFA</span>
            <span className="technologies-text"> TECHNOLOGIES</span>
          </div>
        </span>
      </div>
    </div>
  );
}

export default App;