import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';
import './App.css';

const StarRating = ({ rating, setRating, error, isSubmitted }) => {
  const [hover, setHover] = useState(null);

  return (
    <div className="star-rating-container">
      <div className={`star-rating ${error ? 'star-rating-error' : ''}`}>
        {[...Array(5)].map((star, index) => {
          const ratingValue = index + 1;

          return (
            <label key={index}>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => !isSubmitted && setRating(ratingValue)}
                style={{ display: 'none' }}
                disabled={isSubmitted}  
              />
              <FaStar
                className="star"
                color={ratingValue <= (hover || rating) ? "#5A1899" : "#e4e5e9"}
                size={40}
                onMouseEnter={() => !isSubmitted && setHover(ratingValue)}
                onMouseLeave={() => !isSubmitted && setHover(null)}
              />
            </label>
          );
        })}
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default StarRating;
